"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._isObject = void 0;
/** @internal */
function _isObject(value) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}
exports._isObject = _isObject;
