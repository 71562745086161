"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckResourcesResponse = void 0;
/**
 * The outcome of checking a principal's permissions on a set of resources.
 *
 * @public
 */
class CheckResourcesResponse {
  /**
   * The unique identifier for the request used in audit logs.
   *
   * @remarks
   * Requires the Cerbos policy decision point server to be at least v0.33.
   */
  cerbosCallId;
  /**
   * The identifier for tracing the request.
   */
  requestId;
  /**
   * The outcomes of the permission checks for each resource.
   */
  results;
  constructor({
    cerbosCallId,
    requestId,
    results
  }) {
    this.cerbosCallId = cerbosCallId;
    this.requestId = requestId;
    this.results = results;
  }
  /**
   * Check if the policy decision was that all input actions should be allowed for a resource.
   *
   * @param resource - the resource search criteria.
   * @returns `undefined` if the resource is not present in the results.
   */
  allAllowed(resource) {
    return this.findResult(resource)?.allAllowed();
  }
  /**
   * List the actions that should be allowed for a resource.
   *
   * @param resource - the resource search criteria.
   * @returns `undefined` if the resource is not present in the results.
   */
  allowedActions(resource) {
    return this.findResult(resource)?.allowedActions();
  }
  /**
   * Check if the policy decision was that an action should be allowed for a resource.
   *
   * @param check - the resource search criteria and action to check.
   * @returns `undefined` if the resource or action is not present in the results.
   */
  isAllowed(check) {
    return this.findResult(check.resource)?.isAllowed(check.action);
  }
  /**
   * Find an item from {@link CheckResourcesResponse.results | results} by resource.
   *
   * @param resource - the resource search criteria.
   * @returns `undefined` if the resource is not present in the results.
   */
  findResult(resource) {
    const {
      kind,
      id,
      policyVersion,
      scope
    } = resource;
    return this.results.find(({
      resource
    }) => resource.kind === kind && resource.id === id && (policyVersion === undefined || resource.policyVersion === policyVersion) && (scope === undefined || resource.scope === scope));
  }
  /**
   * Unique schema validation errors for the principal or resource attributes.
   */
  get validationErrors() {
    const unique = {};
    this.results.forEach(({
      validationErrors
    }) => {
      validationErrors.forEach(validationError => {
        const {
          path,
          message,
          source
        } = validationError;
        unique[`${path}:${message}:${source}`] = validationError;
      });
    });
    return Object.values(unique);
  }
}
exports.CheckResourcesResponse = CheckResourcesResponse;
