"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchIsMatchExpr = exports.matchIsMatchNone = exports.matchIsMatchAny = exports.matchIsMatchAll = void 0;
/**
 * Type guard to check if a {@link Match} is a {@link MatchAll}.
 *
 * @public
 */
function matchIsMatchAll(match) {
  return "all" in match;
}
exports.matchIsMatchAll = matchIsMatchAll;
/**
 * Type guard to check if a {@link Match} is a {@link MatchAny}.
 *
 * @public
 */
function matchIsMatchAny(match) {
  return "any" in match;
}
exports.matchIsMatchAny = matchIsMatchAny;
/**
 * Type guard to check if a {@link Match} is a {@link MatchNone}.
 *
 * @public
 */
function matchIsMatchNone(match) {
  return "none" in match;
}
exports.matchIsMatchNone = matchIsMatchNone;
/**
 * Type guard to check if a {@link Match} is a {@link MatchExpr}.
 *
 * @public
 */
function matchIsMatchExpr(match) {
  return "expr" in match;
}
exports.matchIsMatchExpr = matchIsMatchExpr;
