"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlanExpression = void 0;
/**
 * An abstract syntax tree node representing an expression to evaluate.
 *
 * @public
 */
class PlanExpression {
  operator;
  operands;
  constructor(
  /**
   * The operator to invoke.
   */
  operator,
  /**
   * The operands on which to invoke the operator.
   */
  operands) {
    this.operator = operator;
    this.operands = operands;
  }
}
exports.PlanExpression = PlanExpression;
