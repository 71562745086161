"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckResourcesResult = void 0;
const Effect_1 = require("./Effect");
/**
 * The outcome of checking a principal's permissions on single resource.
 *
 * @public
 */
class CheckResourcesResult {
  /**
   * The resource that was checked.
   */
  resource;
  /**
   * The policy decisions for each action.
   */
  actions;
  /**
   * Any schema validation errors for the principal or resource attributes.
   */
  validationErrors;
  /**
   * Additional information about how the policy decisions were reached.
   *
   * @remarks
   * `undefined` if {@link CheckResourcesRequest.includeMetadata | includeMetadata} was `false`.
   */
  metadata;
  /**
   * User-defined outputs from policy rule evaluations.
   *
   * @remarks
   * Requires the Cerbos policy decision point server to be at least v0.27.
   */
  outputs;
  constructor({
    resource,
    actions,
    validationErrors,
    metadata,
    outputs
  }) {
    this.resource = resource;
    this.actions = actions;
    this.validationErrors = validationErrors;
    this.metadata = metadata;
    this.outputs = outputs;
  }
  /**
   * Check if the policy decision was that all input actions should be allowed for the resource.
   */
  allAllowed() {
    return Object.values(this.actions).every(effect => effect === Effect_1.Effect.ALLOW);
  }
  /**
   * List the actions that should be allowed for the resource.
   */
  allowedActions() {
    return Object.keys(this.actions).filter(action => this.actions[action] === Effect_1.Effect.ALLOW);
  }
  /**
   * Check if the policy decision was that a given action should be allowed for the resource.
   *
   * @param action - the action to check.
   * @returns `undefined` if the action is not present in the results.
   */
  isAllowed(action) {
    switch (this.actions[action]) {
      case Effect_1.Effect.ALLOW:
        return true;
      case Effect_1.Effect.DENY:
        return false;
      default:
        return undefined;
    }
  }
  /**
   * Find the value of the user-defined output for a particular policy rule.
   *
   * @param source - the identifier of the policy rule that produced the output.
   * @returns `undefined` if the result does not include an output for the source.
   *
   * @remarks
   * Requires the Cerbos policy decision point server to be at least v0.27.
   */
  output(source) {
    return this.outputs.find(output => output.source === source)?.value;
  }
}
exports.CheckResourcesResult = CheckResourcesResult;
