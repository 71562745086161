"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlanExpressionValue = void 0;
/**
 * An abstract syntax tree node representing a constant value.
 *
 * @public
 */
class PlanExpressionValue {
  value;
  constructor(
  /**
   * The constant value.
   */
  value) {
    this.value = value;
  }
}
exports.PlanExpressionValue = PlanExpressionValue;
