"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._setErrorNameAndStack = exports.ValidationFailed = exports.NotOK = void 0;
const external_1 = require("./types/external");
/**
 * Error thrown when the Cerbos policy decision point server returns an unsuccessful response.
 *
 * @public
 */
class NotOK extends Error {
  code;
  details;
  /**
   * Parse a JSON-serialized unsuccessful response.
   */
  static fromJSON(text) {
    try {
      const error = JSON.parse(text);
      return new NotOK(code(error), details(error));
    } catch (_) {
      return new NotOK(external_1.Status.UNKNOWN, text);
    }
  }
  constructor(
  /**
   * The status code returned by the Cerbos policy decision point server.
   */
  code,
  /**
   * Additional error details.
   */
  details, options) {
    super(`gRPC error ${code} (${external_1.Status[code]}): ${details}`, options);
    this.code = code;
    this.details = details;
    _setErrorNameAndStack(this);
  }
}
exports.NotOK = NotOK;
function code(error) {
  if (has(error, "code") && typeof error.code === "number" && error.code in external_1.Status) {
    return error.code || external_1.Status.UNKNOWN;
  }
  throw new Error("Error does not include expected code");
}
function details(error) {
  if (has(error, "message") && typeof error.message === "string") {
    return error.message;
  }
  throw new Error("Error does not include expected details");
}
function has(object, property) {
  return !!object && Object.prototype.hasOwnProperty.call(object, property);
}
/**
 * Error thrown when input fails schema validation, if the {@link @cerbos/core#Client} is configured with {@link @cerbos/core#Options.onValidationError | onValidationError} set to `"throw"`.
 *
 * @public
 */
class ValidationFailed extends Error {
  validationErrors;
  constructor(
  /**
   * The validation errors that occurred.
   */
  validationErrors) {
    super("Input failed schema validation");
    this.validationErrors = validationErrors;
    _setErrorNameAndStack(this);
  }
}
exports.ValidationFailed = ValidationFailed;
/** @internal */
function _setErrorNameAndStack(error) {
  error.name = error.constructor.name;
  // `Error.captureStackTrace` is not available in all browsers
  if ("captureStackTrace" in Error) {
    Error.captureStackTrace(error, error.constructor);
  }
}
exports._setErrorNameAndStack = _setErrorNameAndStack;
