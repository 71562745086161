"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.planResourcesResponseFromProtobuf = exports.listSchemasResponseFromProtobuf = exports.listPoliciesResponseFromProtobuf = exports.getSchemasResponseFromProtobuf = exports._policyFromProtobuf = exports.getPoliciesResponseFromProtobuf = exports.enablePoliciesResponseFromProtobuf = exports.disablePoliciesResponseFromProtobuf = exports.deleteSchemasResponseFromProtobuf = exports.checkResourcesResponseFromProtobuf = exports.decisionLogEntryFromProtobuf = exports.accessLogEntryFromProtobuf = void 0;
const effect_1 = require("../protobuf/cerbos/effect/v1/effect");
const engine_1 = require("../protobuf/cerbos/engine/v1/engine");
const schema_1 = require("../protobuf/cerbos/schema/v1/schema");
const external_1 = require("../types/external");
function accessLogEntryFromProtobuf({
  entry
}) {
  requireOneOf("ListAuditLogEntriesResponse.entry", entry, "accessLogEntry");
  const {
    callId,
    timestamp,
    peer,
    metadata,
    method,
    statusCode
  } = entry.accessLogEntry;
  requireField("AccessLogEntry.timestamp", timestamp);
  requireField("AccessLogEntry.peer", peer);
  return {
    callId,
    timestamp,
    peer: peerFromProtobuf(peer),
    metadata: auditLogMetadataFromProtobuf(metadata),
    method,
    statusCode
  };
}
exports.accessLogEntryFromProtobuf = accessLogEntryFromProtobuf;
function decisionLogEntryFromProtobuf({
  entry
}) {
  requireOneOf("ListAuditLogEntriesResponse.entry", entry, "decisionLogEntry");
  const {
    callId,
    timestamp,
    peer,
    metadata,
    auditTrail,
    method
  } = entry.decisionLogEntry;
  requireField("DecisionLogEntry.timestamp", timestamp);
  requireField("DecisionLogEntry.peer", peer);
  return {
    callId,
    timestamp,
    peer: peerFromProtobuf(peer),
    metadata: auditLogMetadataFromProtobuf(metadata),
    auditTrail: auditTrailFromProtobuf(auditTrail),
    method: decisionLogEntryMethodFromProtobuf(method)
  };
}
exports.decisionLogEntryFromProtobuf = decisionLogEntryFromProtobuf;
function peerFromProtobuf({
  address,
  authInfo,
  userAgent,
  forwardedFor
}) {
  return {
    address,
    authInfo,
    userAgent,
    forwardedFor
  };
}
function auditLogMetadataFromProtobuf(metadata) {
  return Object.fromEntries(Object.entries(metadata).map(([key, {
    values
  }]) => [key, values]));
}
function auditTrailFromProtobuf({
  effectivePolicies
} = {
  effectivePolicies: {}
}) {
  return {
    effectivePolicies: Object.fromEntries(Object.entries(effectivePolicies).map(([policyId, sourceAttributes]) => [policyId, sourceAttributesFromProtobuf(sourceAttributes)]))
  };
}
function decisionLogEntryMethodFromProtobuf(method) {
  return transformOneOf("DecisionLogEntry.method", method, {
    checkResources: ({
      checkResources
    }) => decisionLogEntryCheckResourcesFromProtobuf(checkResources),
    planResources: ({
      planResources
    }) => decisionLogEntryPlanResourcesFromProtobuf(planResources)
  });
}
function decisionLogEntryCheckResourcesFromProtobuf({
  inputs,
  outputs,
  error
}) {
  return {
    name: "CheckResources",
    inputs: inputs.map(checkInputFromProtobuf),
    outputs: outputs.map(checkOutputFromProtobuf),
    error: error || undefined
  };
}
function checkInputFromProtobuf({
  requestId,
  principal,
  resource,
  actions,
  auxData
}) {
  requireField("CheckInput.principal", principal);
  requireField("CheckInput.resource", resource);
  return {
    requestId,
    principal: principalFromProtobuf(principal),
    resource: resourceFromProtobuf(resource),
    actions,
    auxData: auxData && decodedAuxDataFromProtobuf(auxData)
  };
}
function principalFromProtobuf({
  id,
  roles,
  attr,
  policyVersion,
  scope
}) {
  return {
    id,
    roles,
    attr,
    policyVersion,
    scope
  };
}
function resourceFromProtobuf({
  kind,
  id,
  attr,
  policyVersion,
  scope
}) {
  return {
    kind,
    id,
    attr,
    policyVersion,
    scope
  };
}
function decodedAuxDataFromProtobuf({
  jwt
}) {
  return {
    jwt
  };
}
function checkOutputFromProtobuf({
  requestId,
  resourceId,
  actions,
  effectiveDerivedRoles,
  validationErrors,
  outputs
}) {
  return {
    requestId,
    resourceId,
    actions: Object.fromEntries(Object.entries(actions).map(([action, effect]) => [action, checkOutputActionEffectFromProtobuf(effect)])),
    effectiveDerivedRoles,
    validationErrors: validationErrors.map(validationErrorFromProtobuf),
    outputs: outputs.map(outputResultFromProtobuf)
  };
}
function checkOutputActionEffectFromProtobuf({
  effect,
  policy,
  scope
}) {
  return {
    effect: effectFromProtobuf(effect),
    policy,
    scope
  };
}
function decisionLogEntryPlanResourcesFromProtobuf({
  input,
  output,
  error
}) {
  requireField("DecisionLogEntry.PlanResources.input", input);
  requireField("DecisionLogEntry.PlanResources.output", output);
  return {
    name: "PlanResources",
    input: planResourcesInputFromProtobuf(input),
    output: planResourcesOutputFromProtobuf(output),
    error: error || undefined
  };
}
function planResourcesInputFromProtobuf({
  requestId,
  principal,
  resource,
  action,
  auxData
}) {
  requireField("PlanResourcesInput.principal", principal);
  requireField("PlanResourcesInput.resource", resource);
  return {
    requestId,
    principal: principalFromProtobuf(principal),
    resource: resourceQueryFromProtobuf(resource),
    action,
    auxData: auxData && decodedAuxDataFromProtobuf(auxData)
  };
}
function resourceQueryFromProtobuf({
  kind,
  attr,
  policyVersion,
  scope
}) {
  return {
    kind,
    attr,
    policyVersion,
    scope
  };
}
function planResourcesOutputFromProtobuf({
  requestId,
  filter,
  filterDebug,
  action,
  policyVersion,
  scope,
  validationErrors
}) {
  const base = {
    requestId,
    action,
    policyVersion,
    scope,
    validationErrors: validationErrors.map(validationErrorFromProtobuf)
  };
  requireField("PlanResourcesOutput.filter", filter);
  const kind = planKindFromProtobuf(filter.kind);
  if (kind !== external_1.PlanKind.CONDITIONAL) {
    return {
      ...base,
      kind
    };
  }
  requireField("PlanResourcesFilter.condition", filter.condition);
  return {
    ...base,
    kind,
    condition: planOperandFromProtobuf(filter.condition),
    conditionString: filterDebug
  };
}
function checkResourcesResponseFromProtobuf({
  cerbosCallId,
  requestId,
  results
}) {
  return new external_1.CheckResourcesResponse({
    cerbosCallId,
    requestId,
    results: results.map(checkResourcesResultFromProtobuf)
  });
}
exports.checkResourcesResponseFromProtobuf = checkResourcesResponseFromProtobuf;
function checkResourcesResultFromProtobuf({
  resource,
  actions,
  validationErrors,
  meta,
  outputs
}) {
  requireField("CheckResourcesResponse.ResultEntry", resource);
  return new external_1.CheckResourcesResult({
    resource,
    actions: actionsFromProtobuf(actions),
    validationErrors: validationErrors.map(validationErrorFromProtobuf),
    metadata: meta,
    outputs: outputs.map(outputResultFromProtobuf)
  });
}
function actionsFromProtobuf(actions) {
  return Object.fromEntries(Object.entries(actions).map(([action, effect]) => [action, effectFromProtobuf(effect)]));
}
function effectFromProtobuf(effect) {
  return effect === effect_1.Effect.EFFECT_ALLOW ? external_1.Effect.ALLOW : external_1.Effect.DENY;
}
function validationErrorFromProtobuf({
  path,
  message,
  source
}) {
  return {
    path,
    message,
    source: validationErrorSourceFromProtobuf(source)
  };
}
function validationErrorSourceFromProtobuf(source) {
  return translateEnum("ValidationError.Source", schema_1.ValidationError_Source, source, {
    [schema_1.ValidationError_Source.SOURCE_UNSPECIFIED]: unexpected,
    [schema_1.ValidationError_Source.SOURCE_PRINCIPAL]: external_1.ValidationErrorSource.PRINCIPAL,
    [schema_1.ValidationError_Source.SOURCE_RESOURCE]: external_1.ValidationErrorSource.RESOURCE
  });
}
function outputResultFromProtobuf({
  src,
  val
}) {
  return {
    source: src,
    value: val
  };
}
function deleteSchemasResponseFromProtobuf({
  deletedSchemas
}) {
  return {
    deletedSchemas
  };
}
exports.deleteSchemasResponseFromProtobuf = deleteSchemasResponseFromProtobuf;
function disablePoliciesResponseFromProtobuf({
  disabledPolicies
}) {
  return {
    disabledPolicies
  };
}
exports.disablePoliciesResponseFromProtobuf = disablePoliciesResponseFromProtobuf;
function enablePoliciesResponseFromProtobuf({
  enabledPolicies
}) {
  return {
    enabledPolicies
  };
}
exports.enablePoliciesResponseFromProtobuf = enablePoliciesResponseFromProtobuf;
function getPoliciesResponseFromProtobuf({
  policies
}) {
  return {
    policies: policies.map(_policyFromProtobuf)
  };
}
exports.getPoliciesResponseFromProtobuf = getPoliciesResponseFromProtobuf;
/** @internal */
function _policyFromProtobuf({
  apiVersion,
  description,
  disabled,
  metadata,
  variables,
  policyType
}) {
  return {
    apiVersion,
    description,
    disabled,
    metadata: metadata && policyMetadataFromProtobuf(metadata),
    variables,
    ...policyTypeFromProtobuf(policyType)
  };
}
exports._policyFromProtobuf = _policyFromProtobuf;
function policyMetadataFromProtobuf({
  annotations,
  hash,
  sourceAttributes,
  sourceFile,
  storeIdentifer,
  storeIdentifier
}) {
  return {
    annotations,
    hash,
    sourceAttributes: sourceAttributesFromProtobuf(sourceAttributes),
    sourceFile,
    storeIdentifer: storeIdentifier || storeIdentifer,
    storeIdentifier: storeIdentifier || storeIdentifer
  };
}
function sourceAttributesFromProtobuf({
  attributes
} = {
  attributes: {}
}) {
  return attributes;
}
function policyTypeFromProtobuf(policyType) {
  return transformOneOf("Policy.policyType", policyType, {
    derivedRoles: ({
      derivedRoles
    }) => derivedRolesFromProtobuf(derivedRoles),
    exportVariables: ({
      exportVariables
    }) => exportVariablesFromProtobuf(exportVariables),
    principalPolicy: ({
      principalPolicy
    }) => principalPolicyFromProtobuf(principalPolicy),
    resourcePolicy: ({
      resourcePolicy
    }) => resourcePolicyFromProtobuf(resourcePolicy)
  });
}
function derivedRolesFromProtobuf({
  name,
  definitions,
  variables
}) {
  return {
    derivedRoles: {
      name,
      definitions: definitions.map(derivedRoleDefinitionFromProtobuf),
      variables: variables && variablesFromProtobuf(variables)
    }
  };
}
function derivedRoleDefinitionFromProtobuf({
  name,
  parentRoles,
  condition
}) {
  return {
    name,
    parentRoles,
    condition: condition && conditionFromProtobuf(condition)
  };
}
function conditionFromProtobuf({
  condition
}) {
  requireOneOf("Condition.condition", condition, "match");
  return {
    match: matchFromProtobuf(condition.match)
  };
}
function matchFromProtobuf({
  op
}) {
  return transformOneOf("Match.op", op, {
    all: ({
      all
    }) => ({
      all: matchesFromProtobuf(all)
    }),
    any: ({
      any
    }) => ({
      any: matchesFromProtobuf(any)
    }),
    none: ({
      none
    }) => ({
      none: matchesFromProtobuf(none)
    }),
    expr: ({
      expr
    }) => ({
      expr
    })
  });
}
function matchesFromProtobuf({
  of
}) {
  return {
    of: of.map(matchFromProtobuf)
  };
}
function variablesFromProtobuf({
  import: imports,
  local
}) {
  return {
    import: imports,
    local
  };
}
function exportVariablesFromProtobuf({
  name,
  definitions
}) {
  return {
    exportVariables: {
      name,
      definitions
    }
  };
}
function principalPolicyFromProtobuf({
  principal,
  version,
  rules,
  scope,
  variables
}) {
  return {
    principalPolicy: {
      principal,
      version,
      rules: rules.map(principalRuleFromProtobuf),
      scope,
      variables: variables && variablesFromProtobuf(variables)
    }
  };
}
function principalRuleFromProtobuf({
  resource,
  actions
}) {
  return {
    resource,
    actions: actions.map(principalRuleActionFromProtobuf)
  };
}
function principalRuleActionFromProtobuf({
  action,
  effect,
  condition,
  name,
  output
}) {
  return {
    action,
    effect: effectFromProtobuf(effect),
    condition: condition && conditionFromProtobuf(condition),
    name,
    output: output && outputFromProtobuf(output)
  };
}
function outputFromProtobuf({
  expr,
  when
}) {
  const output = {};
  if (expr) {
    output.expr = expr;
  }
  if (when) {
    output.when = when;
  }
  return output;
}
function resourcePolicyFromProtobuf({
  resource,
  version,
  importDerivedRoles,
  rules,
  schemas,
  scope,
  variables
}) {
  return {
    resourcePolicy: {
      resource,
      version,
      importDerivedRoles,
      rules: rules.map(resourceRuleFromProtobuf),
      schemas: schemas && schemaRefsFromProtobuf(schemas),
      scope,
      variables: variables && variablesFromProtobuf(variables)
    }
  };
}
function resourceRuleFromProtobuf({
  actions,
  effect,
  derivedRoles,
  roles,
  condition,
  name,
  output
}) {
  return {
    actions,
    effect: effectFromProtobuf(effect),
    derivedRoles,
    roles,
    condition: condition && conditionFromProtobuf(condition),
    name,
    output: output && outputFromProtobuf(output)
  };
}
function schemaRefsFromProtobuf({
  principalSchema,
  resourceSchema
}) {
  return {
    principalSchema: principalSchema && schemaRefFromProtobuf(principalSchema),
    resourceSchema: resourceSchema && schemaRefFromProtobuf(resourceSchema)
  };
}
function schemaRefFromProtobuf({
  ref,
  ignoreWhen
}) {
  return {
    ref,
    ignoreWhen: ignoreWhen && {
      actions: ignoreWhen.actions
    }
  };
}
function getSchemasResponseFromProtobuf({
  schemas
}) {
  return {
    schemas: schemas.map(schemaFromProtobuf)
  };
}
exports.getSchemasResponseFromProtobuf = getSchemasResponseFromProtobuf;
function schemaFromProtobuf({
  id,
  definition
}) {
  return {
    id,
    definition: new external_1.SchemaDefinition(definition)
  };
}
function listPoliciesResponseFromProtobuf({
  policyIds
}) {
  return {
    ids: policyIds
  };
}
exports.listPoliciesResponseFromProtobuf = listPoliciesResponseFromProtobuf;
function listSchemasResponseFromProtobuf({
  schemaIds
}) {
  return {
    ids: schemaIds
  };
}
exports.listSchemasResponseFromProtobuf = listSchemasResponseFromProtobuf;
function planResourcesResponseFromProtobuf({
  cerbosCallId,
  requestId,
  filter,
  validationErrors,
  meta
}) {
  const base = {
    cerbosCallId,
    requestId,
    validationErrors: validationErrors.map(validationErrorFromProtobuf),
    metadata: meta && planResourcesMetadataFromProtobuf(meta)
  };
  requireField("PlanResourcesResponse.filter", filter);
  const kind = planKindFromProtobuf(filter.kind);
  if (kind !== external_1.PlanKind.CONDITIONAL) {
    return {
      ...base,
      kind
    };
  }
  requireField("PlanResourcesFilter.condition", filter.condition);
  return {
    ...base,
    kind,
    condition: planOperandFromProtobuf(filter.condition)
  };
}
exports.planResourcesResponseFromProtobuf = planResourcesResponseFromProtobuf;
function planKindFromProtobuf(kind) {
  return translateEnum("PlanResourcesFilter.Kind", engine_1.PlanResourcesFilter_Kind, kind, {
    [engine_1.PlanResourcesFilter_Kind.KIND_UNSPECIFIED]: unexpected,
    [engine_1.PlanResourcesFilter_Kind.KIND_ALWAYS_ALLOWED]: external_1.PlanKind.ALWAYS_ALLOWED,
    [engine_1.PlanResourcesFilter_Kind.KIND_ALWAYS_DENIED]: external_1.PlanKind.ALWAYS_DENIED,
    [engine_1.PlanResourcesFilter_Kind.KIND_CONDITIONAL]: external_1.PlanKind.CONDITIONAL
  });
}
function planOperandFromProtobuf({
  node
}) {
  return transformOneOf("PlanResourcesFilter.Expression.Operand.node", node, {
    expression: ({
      expression
    }) => new external_1.PlanExpression(expression.operator, expression.operands.map(planOperandFromProtobuf)),
    value: ({
      value
    }) => new external_1.PlanExpressionValue(value ?? null),
    variable: ({
      variable
    }) => new external_1.PlanExpressionVariable(variable)
  });
}
function planResourcesMetadataFromProtobuf({
  filterDebug,
  matchedScope
}) {
  return {
    conditionString: filterDebug,
    matchedScope
  };
}
const unexpected = Symbol("unexpected");
function isUnexpected(value) {
  return value === unexpected || value === undefined;
}
function translateEnum(descriptor, source, value, translate) {
  const result = translate[value];
  if (isUnexpected(result)) {
    const wanted = Object.entries(source).filter(([, value]) => typeof value === "number" && !isUnexpected(translate[value])).map(([key, value]) => `${key}:${value}`).join("|");
    const got = source[value] ? `${source[value]}:${value}` : value;
    throw new Error(`Unexpected ${descriptor}: wanted ${wanted}, got ${got}`);
  }
  return result;
}
function transformOneOf(descriptor, oneOf, transforms) {
  requireField(descriptor, oneOf);
  const transform = transforms[oneOf.$case];
  if (isUnexpected(transform)) {
    throw new Error(`Unexpected ${descriptor}: wanted ${Object.keys(transforms).join("|")}, got ${oneOf.$case}`);
  }
  return transform(oneOf);
}
function requireOneOf(descriptor, oneOf, $case) {
  requireField(descriptor, oneOf);
  if (oneOf.$case !== $case) {
    throw new Error(`Unexpected ${descriptor}: wanted ${$case}, got ${oneOf.$case}`);
  }
}
function requireField(descriptor, value) {
  if (value === undefined) {
    throw new Error(`Missing ${descriptor}`);
  }
}
