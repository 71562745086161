import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { CheckResourcesResult, Effect, ResourceCheck } from '@cerbos/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ResourceEnum } from '@supy.api/permissions';

import { Role } from '@supy/common';

import { AUTHZ_DEFAULT_ID, CheckPermissionAllowance, PermissionActionState, PermissionCheck } from '../../core';
import { AuthzService } from '../../services';
import { CheckAccessMany, IsAllowed, PatchPermissionsMetadata } from '../actions';

const PERMISSIONS_STATE_TOKEN = new StateToken<PermissionsStateModel>('permissions');

export interface PermissionsStateModel {
  readonly permissions: PermissionCheck[];
  readonly metadata: PermissionsStateMetadata;
}

export interface PermissionsStateMetadata {
  readonly selectedRetailerId: string | null;
  readonly userId: string | null;
  readonly retailerIds: string[];
  readonly roles: Role[];
}

export const PERMISSION_NOT_FOUND = 'not-found';

@State<PermissionsStateModel>({
  name: PERMISSIONS_STATE_TOKEN,
  defaults: {
    permissions: [],
    metadata: {
      selectedRetailerId: null,
      userId: null,
      roles: [],
      retailerIds: [],
    },
  },
})
@Injectable()
export class PermissionsState {
  constructor(private readonly authzService: AuthzService) {}

  @Selector()
  static permissions(state: PermissionsStateModel) {
    return state.permissions;
  }

  @Selector()
  static permissionsMap(state: PermissionsStateModel) {
    return new Map(state.permissions.map(permission => [permission.resource, permission.actions]));
  }

  static isPermissionAllowed(permissionCheck: CheckPermissionAllowance) {
    return createSelector([PermissionsState], (state: PermissionsStateModel) => {
      const permission = state.permissions.find(
        permission =>
          permission.resource === permissionCheck.resource && permission.actions[permissionCheck.action] !== undefined,
      );

      if (!permission) {
        return PERMISSION_NOT_FOUND;
      }

      return permission.actions[permissionCheck.action];
    });
  }

  @Action(CheckAccessMany, { cancelUncompleted: true })
  checkAccessMany(ctx: StateContext<PermissionsStateModel>, { payload, forceFetch }: CheckAccessMany) {
    const permissions = ctx.getState().permissions;

    const resources: ResourceCheck[] = [];

    if (!forceFetch && permissions.length) {
      for (const resource of payload.resources) {
        if (!this.checkIfPermissionAndActionExists(permissions, resource)) {
          resources.push(resource);
        }
      }

      if (!resources.length) {
        return;
      }

      payload = { ...payload, resources };
    }

    return this.authzService.checkResources(payload).pipe(
      tap(({ results }) => {
        const existingPermissions = ctx.getState().permissions;

        const newPermissions = results.map(result => ({
          resource: result.resource.kind as ResourceEnum,
          actions: this.mapActions(result.actions),
        }));

        const permissions = existingPermissions.reduce((acc, permission) => {
          const newPermission = newPermissions.find(newPermission => newPermission.resource === permission.resource);

          if (!newPermission) {
            return [...acc, permission];
          }

          return [
            ...acc,
            {
              ...permission,
              actions: { ...permission.actions, ...newPermission.actions },
            },
          ];
        }, newPermissions);

        ctx.patchState({ permissions });
      }),
    );
  }

  @Action(IsAllowed)
  isAllowed(ctx: StateContext<PermissionsStateModel>, { payload }: IsAllowed) {
    const isAllowedRequest = this.getIsAllowedRequest(ctx.getState(), payload);

    return this.authzService.isAllowed(isAllowedRequest).pipe(
      tap(isAllowed => {
        const permission = ctx
          .getState()
          .permissions.find(permission => permission.resource === isAllowedRequest.resource.kind);

        if (!permission) {
          const newPermission: PermissionCheck = {
            resource: isAllowedRequest.resource.kind,
            actions: {
              [isAllowedRequest.action]: isAllowed,
            },
          };

          ctx.patchState({ permissions: [...ctx.getState().permissions, newPermission] });

          return;
        }

        const newPermission = {
          ...permission,
          actions: { ...permission.actions, [isAllowedRequest.action]: isAllowed },
        };

        ctx.patchState({
          permissions: ctx
            .getState()
            .permissions.map(permission =>
              permission.resource === isAllowedRequest.resource.kind ? newPermission : permission,
            ),
        });
      }),
    );
  }

  @Action(PatchPermissionsMetadata)
  setPermissionsMetadata(ctx: StateContext<PermissionsStateModel>, { payload }: PatchPermissionsMetadata) {
    ctx.patchState({ metadata: { ...ctx.getState()?.metadata, ...payload } });
  }

  private getIsAllowedRequest(state: PermissionsStateModel, checkPermissions: CheckPermissionAllowance) {
    return {
      principal: {
        id: state.metadata.userId as string,
        roles: state.metadata.roles.map(role => role.identifier),
        attributes: {
          retailerIds: state.metadata?.retailerIds,
        },
      },
      resource: {
        id: AUTHZ_DEFAULT_ID,
        kind: checkPermissions.resource,
        attributes: {
          retailerId: state.metadata?.selectedRetailerId,
        },
      },
      action: checkPermissions.action,
    };
  }

  private mapActions(actions: CheckResourcesResult['actions']): PermissionActionState {
    const mappedPermissions: PermissionActionState = {};

    for (const [key, value] of Object.entries(actions)) {
      mappedPermissions[key] = value === Effect.ALLOW;
    }

    return mappedPermissions;
  }

  private checkIfPermissionAndActionExists(permissions: PermissionCheck[], resource: ResourceCheck): boolean {
    const permission = permissions.find(permission => permission.resource === (resource.resource.kind as ResourceEnum));

    if (!permission) {
      return false;
    }

    return resource.actions.every(action => permission.actions[action] !== undefined);
  }
}
