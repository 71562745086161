"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditLogFilterIsTail = exports.auditLogFilterIsSince = exports.auditLogFilterIsBetween = void 0;
/**
 * Type guard to check if an {@link AuditLogFilter} is an {@link AuditLogFilterBetween}.
 *
 * @public
 */
function auditLogFilterIsBetween(filter) {
  return "start" in filter;
}
exports.auditLogFilterIsBetween = auditLogFilterIsBetween;
/**
 * Type guard to check if an {@link AuditLogFilter} is an {@link AuditLogFilterSince}.
 *
 * @public
 */
function auditLogFilterIsSince(filter) {
  return "since" in filter;
}
exports.auditLogFilterIsSince = auditLogFilterIsSince;
/**
 * Type guard to check if an {@link AuditLogFilter} is an {@link AuditLogFilterTail}.
 *
 * @public
 */
function auditLogFilterIsTail(filter) {
  return "tail" in filter;
}
exports.auditLogFilterIsTail = auditLogFilterIsTail;
