"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decisionLogEntryMethodIsPlanResources = exports.decisionLogEntryMethodIsCheckResources = void 0;
/**
 * Type guard to check if a {@link DecisionLogEntryMethod} is a {@link DecisionLogEntryCheckResources}.
 *
 * @public
 */
function decisionLogEntryMethodIsCheckResources(method) {
  return method.name === "CheckResources";
}
exports.decisionLogEntryMethodIsCheckResources = decisionLogEntryMethodIsCheckResources;
/**
 * Type guard to check if a {@link DecisionLogEntryMethod} is a {@link DecisionLogEntryPlanResources}.
 *
 * @public
 */
function decisionLogEntryMethodIsPlanResources(method) {
  return method.name === "PlanResources";
}
exports.decisionLogEntryMethodIsPlanResources = decisionLogEntryMethodIsPlanResources;
