"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.planResourcesResponseIsUnconditional = exports.planResourcesResponseIsConditional = void 0;
const PlanKind_1 = require("./PlanKind");
/**
 * Type guard to check if a {@link PlanResourcesResponse} is a {@link PlanResourcesConditionalResponse}.
 *
 * @public
 */
function planResourcesResponseIsConditional(output) {
  return output.kind === PlanKind_1.PlanKind.CONDITIONAL;
}
exports.planResourcesResponseIsConditional = planResourcesResponseIsConditional;
/**
 * Type guard to check if a {@link PlanResourcesResponse} is a {@link PlanResourcesUnconditionalResponse}.
 *
 * @public
 */
function planResourcesResponseIsUnconditional(output) {
  return output.kind !== PlanKind_1.PlanKind.CONDITIONAL;
}
exports.planResourcesResponseIsUnconditional = planResourcesResponseIsUnconditional;
