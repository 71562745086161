"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.planResourcesOutputIsUnconditional = exports.planResourcesOutputIsConditional = void 0;
const PlanKind_1 = require("./PlanKind");
/**
 * Type guard to check if a {@link PlanResourcesOutput} is a {@link PlanResourcesConditionalOutput}.
 *
 * @public
 */
function planResourcesOutputIsConditional(output) {
  return output.kind === PlanKind_1.PlanKind.CONDITIONAL;
}
exports.planResourcesOutputIsConditional = planResourcesOutputIsConditional;
/**
 * Type guard to check if a {@link PlanResourcesOutput} is a {@link PlanResourcesUnconditionalOutput}.
 *
 * @public
 */
function planResourcesOutputIsUnconditional(output) {
  return output.kind !== PlanKind_1.PlanKind.CONDITIONAL;
}
exports.planResourcesOutputIsUnconditional = planResourcesOutputIsUnconditional;
