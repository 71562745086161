"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.planResourcesRequestToProtobuf = exports.listPoliciesRequestToProtobuf = exports.listDecisionLogEntriesRequestToProtobuf = exports.listAccessLogEntriesRequestToProtobuf = exports.getSchemasRequestToProtobuf = exports.getPoliciesRequestToProtobuf = exports.enablePoliciesRequestToProtobuf = exports.disablePoliciesRequestToProtobuf = exports.deleteSchemasRequestToProtobuf = exports.checkResourcesRequestToProtobuf = exports.addOrUpdateSchemasRequestToProtobuf = exports.addOrUpdatePoliciesRequestToProtobuf = void 0;
const uuid_1 = require("uuid");
const effect_1 = require("../protobuf/cerbos/effect/v1/effect");
const request_1 = require("../protobuf/cerbos/request/v1/request");
const external_1 = require("../types/external");
const encoder = new TextEncoder();
function addOrUpdatePoliciesRequestToProtobuf({
  policies
}) {
  return {
    policies: policies.map(policyToProtobuf)
  };
}
exports.addOrUpdatePoliciesRequestToProtobuf = addOrUpdatePoliciesRequestToProtobuf;
function policyToProtobuf(policy) {
  const {
    apiVersion = "api.cerbos.dev/v1",
    description = "",
    disabled = false,
    variables = {}
  } = policy;
  return {
    apiVersion,
    description,
    disabled,
    jsonSchema: "",
    metadata: undefined,
    policyType: policyTypeToProtobuf(policy),
    variables
  };
}
function policyTypeToProtobuf(policy) {
  if ((0, external_1.policyIsDerivedRoles)(policy)) {
    return {
      $case: "derivedRoles",
      derivedRoles: derivedRolesToProtobuf(policy)
    };
  }
  if ((0, external_1.policyIsExportVariables)(policy)) {
    return {
      $case: "exportVariables",
      exportVariables: exportVariablesToProtobuf(policy)
    };
  }
  if ((0, external_1.policyIsPrincipalPolicy)(policy)) {
    return {
      $case: "principalPolicy",
      principalPolicy: principalPolicyToProtobuf(policy)
    };
  }
  if ((0, external_1.policyIsResourcePolicy)(policy)) {
    return {
      $case: "resourcePolicy",
      resourcePolicy: resourcePolicyToProtobuf(policy)
    };
  }
  throw new Error(`Unknown policy type: ${JSON.stringify(policy, null, 2)}`);
}
function derivedRolesToProtobuf({
  derivedRoles: {
    name,
    definitions,
    variables
  }
}) {
  return {
    name,
    definitions: definitions.map(derivedRoleDefinitionToProtobuf),
    variables: variables && variablesToProtobuf(variables)
  };
}
function derivedRoleDefinitionToProtobuf({
  name,
  parentRoles,
  condition
}) {
  return {
    name,
    parentRoles,
    condition: condition && conditionToProtobuf(condition)
  };
}
function conditionToProtobuf({
  match
}) {
  return {
    condition: {
      $case: "match",
      match: matchToProtobuf(match)
    }
  };
}
function matchToProtobuf(match) {
  if ((0, external_1.matchIsMatchAll)(match)) {
    return {
      op: {
        $case: "all",
        all: matchesToProtobuf(match.all)
      }
    };
  }
  if ((0, external_1.matchIsMatchAny)(match)) {
    return {
      op: {
        $case: "any",
        any: matchesToProtobuf(match.any)
      }
    };
  }
  if ((0, external_1.matchIsMatchNone)(match)) {
    return {
      op: {
        $case: "none",
        none: matchesToProtobuf(match.none)
      }
    };
  }
  if ((0, external_1.matchIsMatchExpr)(match)) {
    return {
      op: {
        $case: "expr",
        expr: match.expr
      }
    };
  }
  throw new Error(`Unknown match type: ${JSON.stringify(match, null, 2)}`);
}
function matchesToProtobuf({
  of
}) {
  return {
    of: of.map(matchToProtobuf)
  };
}
function variablesToProtobuf({
  import: imports = [],
  local = {}
}) {
  return {
    import: imports,
    local
  };
}
function exportVariablesToProtobuf({
  exportVariables: {
    name,
    definitions
  }
}) {
  return {
    name,
    definitions
  };
}
function principalPolicyToProtobuf({
  principalPolicy: {
    principal,
    version,
    rules,
    scope = "",
    variables
  }
}) {
  return {
    principal,
    version,
    rules: rules.map(principalRuleToProtobuf),
    scope,
    variables: variables && variablesToProtobuf(variables)
  };
}
function principalRuleToProtobuf({
  resource,
  actions
}) {
  return {
    resource,
    actions: actions.map(principalRuleActionToProtobuf)
  };
}
function principalRuleActionToProtobuf({
  action,
  effect,
  condition,
  name = "",
  output
}) {
  return {
    action,
    effect: effectToProtobuf(effect),
    condition: condition && conditionToProtobuf(condition),
    name,
    output: output && outputToProtobuf(output)
  };
}
function effectToProtobuf(effect) {
  return effect === external_1.Effect.ALLOW ? effect_1.Effect.EFFECT_ALLOW : effect_1.Effect.EFFECT_DENY;
}
function outputToProtobuf({
  expr = "",
  when
}) {
  return {
    expr,
    when: when && outputExpressionsToProtobuf(when)
  };
}
function outputExpressionsToProtobuf({
  ruleActivated = "",
  conditionNotMet = ""
}) {
  return {
    ruleActivated,
    conditionNotMet
  };
}
function resourcePolicyToProtobuf({
  resourcePolicy: {
    resource,
    version,
    importDerivedRoles = [],
    rules,
    scope = "",
    schemas,
    variables
  }
}) {
  return {
    resource,
    version,
    importDerivedRoles,
    rules: rules.map(resourceRuleToProtobuf),
    scope,
    schemas: schemas && policySchemasToProtobuf(schemas),
    variables: variables && variablesToProtobuf(variables)
  };
}
function resourceRuleToProtobuf({
  actions,
  effect,
  derivedRoles = [],
  roles = [],
  condition,
  name = "",
  output
}) {
  return {
    actions,
    effect: effectToProtobuf(effect),
    derivedRoles,
    roles,
    condition: condition && conditionToProtobuf(condition),
    name,
    output: output && outputToProtobuf(output)
  };
}
function policySchemasToProtobuf({
  principalSchema,
  resourceSchema
}) {
  return {
    principalSchema: principalSchema && policySchemaToProtobuf(principalSchema),
    resourceSchema: resourceSchema && policySchemaToProtobuf(resourceSchema)
  };
}
function policySchemaToProtobuf({
  ref,
  ignoreWhen
}) {
  return {
    ref,
    ignoreWhen
  };
}
function addOrUpdateSchemasRequestToProtobuf({
  schemas
}) {
  return {
    schemas: schemas.map(schemaToProtobuf)
  };
}
exports.addOrUpdateSchemasRequestToProtobuf = addOrUpdateSchemasRequestToProtobuf;
function schemaToProtobuf({
  id,
  definition
}) {
  return {
    id,
    definition: schemaDefinitionToProtobuf(definition)
  };
}
function schemaDefinitionToProtobuf(definition) {
  if (definition instanceof Uint8Array) {
    return definition;
  }
  if (definition instanceof external_1.SchemaDefinition) {
    return definition.bytes;
  }
  if (typeof definition === "string") {
    return encoder.encode(definition);
  }
  return encoder.encode(JSON.stringify(definition));
}
function checkResourcesRequestToProtobuf({
  principal,
  resources,
  auxData,
  includeMetadata = false,
  requestId = (0, uuid_1.v4)()
}) {
  return {
    principal: principalToProtobuf(principal),
    resources: resources.map(resourceCheckToProtobuf),
    auxData: auxData && auxDataToProtobuf(auxData),
    includeMeta: includeMetadata,
    requestId
  };
}
exports.checkResourcesRequestToProtobuf = checkResourcesRequestToProtobuf;
function principalToProtobuf({
  id,
  roles,
  attr = {},
  attributes = {},
  policyVersion = "",
  scope = ""
}) {
  return {
    id,
    roles,
    attr: {
      ...attributes,
      ...attr
    },
    policyVersion,
    scope
  };
}
function resourceCheckToProtobuf({
  resource,
  actions
}) {
  return {
    resource: resourceToProtobuf(resource),
    actions
  };
}
function resourceToProtobuf({
  kind,
  id,
  attr = {},
  attributes = {},
  policyVersion = "",
  scope = ""
}) {
  return {
    kind,
    id,
    attr: {
      ...attributes,
      ...attr
    },
    policyVersion,
    scope
  };
}
function auxDataToProtobuf({
  jwt
}) {
  if (!jwt) {
    return undefined;
  }
  return {
    jwt: jwtToProtobuf(jwt)
  };
}
function jwtToProtobuf({
  token,
  keySetId = ""
}) {
  return {
    token,
    keySetId
  };
}
function deleteSchemasRequestToProtobuf({
  ids
}) {
  return {
    id: ids
  };
}
exports.deleteSchemasRequestToProtobuf = deleteSchemasRequestToProtobuf;
function disablePoliciesRequestToProtobuf({
  ids
}) {
  return {
    id: ids
  };
}
exports.disablePoliciesRequestToProtobuf = disablePoliciesRequestToProtobuf;
function enablePoliciesRequestToProtobuf({
  ids
}) {
  return {
    id: ids
  };
}
exports.enablePoliciesRequestToProtobuf = enablePoliciesRequestToProtobuf;
function getPoliciesRequestToProtobuf({
  ids
}) {
  return {
    id: ids
  };
}
exports.getPoliciesRequestToProtobuf = getPoliciesRequestToProtobuf;
function getSchemasRequestToProtobuf({
  ids
}) {
  return {
    id: ids
  };
}
exports.getSchemasRequestToProtobuf = getSchemasRequestToProtobuf;
function listAccessLogEntriesRequestToProtobuf({
  filter
}) {
  return {
    kind: request_1.ListAuditLogEntriesRequest_Kind.KIND_ACCESS,
    filter: auditLogFilterToProtobuf(filter)
  };
}
exports.listAccessLogEntriesRequestToProtobuf = listAccessLogEntriesRequestToProtobuf;
function listDecisionLogEntriesRequestToProtobuf({
  filter
}) {
  return {
    kind: request_1.ListAuditLogEntriesRequest_Kind.KIND_DECISION,
    filter: auditLogFilterToProtobuf(filter)
  };
}
exports.listDecisionLogEntriesRequestToProtobuf = listDecisionLogEntriesRequestToProtobuf;
function auditLogFilterToProtobuf(filter) {
  if ((0, external_1.auditLogFilterIsBetween)(filter)) {
    return {
      $case: "between",
      between: {
        start: filter.start,
        end: filter.end
      }
    };
  }
  if ((0, external_1.auditLogFilterIsSince)(filter)) {
    return {
      $case: "since",
      since: durationToProtobuf(filter.since)
    };
  }
  if ((0, external_1.auditLogFilterIsTail)(filter)) {
    return {
      $case: "tail",
      tail: filter.tail
    };
  }
  return undefined;
}
function durationToProtobuf(duration) {
  const [seconds, nanos] = duration.toFixed(9).split(".", 2);
  return {
    seconds,
    nanos: parseInt(nanos, 10)
  };
}
function listPoliciesRequestToProtobuf({
  includeDisabled = false,
  nameRegexp = "",
  scopeRegexp = "",
  versionRegexp = ""
}) {
  return {
    includeDisabled,
    nameRegexp,
    scopeRegexp,
    versionRegexp
  };
}
exports.listPoliciesRequestToProtobuf = listPoliciesRequestToProtobuf;
function planResourcesRequestToProtobuf({
  principal,
  resource,
  action,
  auxData,
  includeMetadata = false,
  requestId = (0, uuid_1.v4)()
}) {
  return {
    principal: principalToProtobuf(principal),
    resource: resourceQueryToProtobuf(resource),
    action,
    auxData: auxData && auxDataToProtobuf(auxData),
    includeMeta: includeMetadata,
    requestId
  };
}
exports.planResourcesRequestToProtobuf = planResourcesRequestToProtobuf;
function resourceQueryToProtobuf({
  kind,
  attr = {},
  attributes = {},
  policyVersion = "",
  scope = ""
}) {
  return {
    kind,
    attr: {
      ...attributes,
      ...attr
    },
    policyVersion,
    scope
  };
}
