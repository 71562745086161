"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.policyIsResourcePolicy = exports.policyIsPrincipalPolicy = exports.policyIsExportVariables = exports.policyIsDerivedRoles = void 0;
/**
 * Type guard to check if a {@link Policy} is a set of {@link DerivedRoles}.
 *
 * @public
 */
function policyIsDerivedRoles(policy) {
  return "derivedRoles" in policy;
}
exports.policyIsDerivedRoles = policyIsDerivedRoles;
/**
 * Type guard to check if a {@link Policy} is a set of {@link ExportVariables}.
 *
 * @public
 */
function policyIsExportVariables(policy) {
  return "exportVariables" in policy;
}
exports.policyIsExportVariables = policyIsExportVariables;
/**
 * Type guard to check if a {@link Policy} is a {@link PrincipalPolicy}.
 *
 * @public
 */
function policyIsPrincipalPolicy(policy) {
  return "principalPolicy" in policy;
}
exports.policyIsPrincipalPolicy = policyIsPrincipalPolicy;
/**
 * Type guard to check if a {@link Policy} is a {@link ResourcePolicy}.
 *
 * @public
 */
function policyIsResourcePolicy(policy) {
  return "resourcePolicy" in policy;
}
exports.policyIsResourcePolicy = policyIsResourcePolicy;
