"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlanExpressionVariable = void 0;
/**
 * An abstract syntax tree node representing a variable whose value was unknown when producing the query plan.
 *
 * @public
 */
class PlanExpressionVariable {
  name;
  constructor(
  /**
   * The name of the variable.
   */
  name) {
    this.name = name;
  }
}
exports.PlanExpressionVariable = PlanExpressionVariable;
