"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SchemaDefinition = void 0;
const _isObject_1 = require("./_isObject");
const utf8Decoder = new TextDecoder("utf-8", {
  fatal: true
});
/**
 * Definition of a JSON schema used to validate principal or resource attributes.
 *
 * @public
 */
class SchemaDefinition {
  bytes;
  constructor(
  /**
   * The UTF-8 bytes of the JSON-serialized schema definition.
   */
  bytes) {
    this.bytes = bytes;
  }
  /**
   * The parsed schema definition.
   */
  toObject() {
    const object = JSON.parse(this.toString());
    if (!(0, _isObject_1._isObject)(object)) {
      throw new Error("Schema definition is not an object");
    }
    return object;
  }
  /**
   * The JSON-serialized schema definition.
   */
  toString() {
    return utf8Decoder.decode(this.bytes);
  }
}
exports.SchemaDefinition = SchemaDefinition;
